<template>
  <div class="notice-container">
    <div class="filter-panel">
      <input
          v-model="search"
          class="cs-input"
          placeholder="搜索新闻标题"
          style="margin-bottom: 20px"
          type="text"
      />
      <button
          class="btn btn-primary"
          style="padding: 0; margin-bottom: 20px"
          @click="onRegionConfirm()"
      >
        查询
      </button>

      <button
          class="btn btn-primary sticky-right"
          type="button"
          @click="showAddRobotDialog(1)"
      >
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#icon-menujiahao"></use>
        </svg>
        新闻
      </button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:thead>
          <tr>
            <th>发布时间</th>
            <th>新闻标题</th>
            <th>配图</th>
            <th>简述</th>
            <th>跳转链接</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in journaList" :key="item.id">
            <td style="min-width: 125px">{{ item.updateTime }}</td>
            <td>{{ item.title }}</td>
            <td>
              <span v-if="item.picUrl" class="allow-click" @click="lookImage(item.picUrl)"
              >查看</span
              >
              <span v-else>-</span>
            </td>
            <td>
              <span v-if="item.content" class="allow-click" @click="lookJianShu(item.content)"
              >查看</span
              >
              <span v-else>-</span>
            </td>
            <td>
              <a v-if="item.contentUrl" :href="item.contentUrl" class="allow-click" target="_blank"
              >查看</a
              >
              <span v-else>-</span>
            </td>
            <td>
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="min-width: 76px; width: 100px">
                  <li>
                    <a style="width: 100%" @click="showAddRobotDialog(2,item)"
                    >修改</a
                    >
                  </li>
                  <li>
                    <a style="width: 100%" @click="deleteRobotVisible = true;addRobotInfo.id=item.id"
                    >删除</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination></Pagination>
      <CSDialog
          :dialog-visible="deleteRobotVisible"
          dialog-confirm-btn-text="确定"
          dialog-header-class=" "
          dialog-header-icon="icon-menua-zu13"
          dialog-width="540px"
          @onClose="deleteRobotVisible = false"
          @onConfirm="deleteRobot"
      >
        <template v-slot:dialog-content>
          <div style="padding: 30px; font-size: 24px; text-align: center">
            确定删除吗？
          </div>
        </template>
      </CSDialog>
      <CSDialog
          :dialog-title="addRobotDialogTitle"
          :dialog-visible="addRobotVisible"
          dialog-width="930px"
          dialogConfirmBtnText="发布"
          @onClose="addRobotVisible = false"
          @onConfirm="
          addRobotDialogTitle == '添加新闻'
            ? addRobotDevice()
            : editRobotDevice()
        "
      >
        <template v-slot:dialog-content>
          <div style="padding: 30px">
            <div class="item">
              <div class="notice_title">新闻标题</div>
              <input
                  v-model="addRobotInfo.robotName"
                  class="hy-input"
                  maxlength="45"
                  placeholder="限45个字"
                  type="text"
              />
            </div>
          </div>
          <div style="padding: 0px 30px 30px 30px">
            <div class="item">
              <div class="notice_title">新闻配图</div>
              <div style="display: inline-block; margin-bottom: 20px">
                <div
                    v-if="addRobotInfo.iconUrl === ''"
                    class="upload-photo"
                    style="width: 125px; height: 90px"
                    @click="triggerChoosePhoto"
                >
                  <div class="text-center">
                    <img
                        alt=""
                        src="../../assets/upload.png"
                        style="margin: 5px auto 5px"
                    />
                    <p style="color: #999">上传照片</p>
                    <p style="color: #999">尺寸250*130</p>
                  </div>
                </div>
                <div
                    v-else
                    class="photo-view"
                    style="width: 125px; height: 90px"
                >
                  <img
                      :src="addRobotInfo.iconUrl"
                      style="width: 125px; height: 90px"
                  />
                  <i class="icon-close" @click="deletePhotos()"></i>
                </div>
                <input
                    id="uploadStaffPhoto"
                    accept="images/*"
                    class="file"
                    hidden
                    type="file"
                    @change="chooseStaffPhoto($event)"
                />
              </div>
            </div>
          </div>
          <div style="padding: 0px 30px 30px 30px">
            <div class="item">
              <div class="notice_title" style="height: 200px">简述</div>
              <textarea
                  v-model="addRobotInfo.containerNotice"
                  class="hy-input"
                  maxlength="200"
                  placeholder="限200个字"
                  style="
                  width: 620px;
                  height: 200px;
                  padding: 0 15px;
                  resize: none;
                  border: 0px;
                  border-radius: 10px;
                  background-color: #f0f0f0;
                "
              />
            </div>
          </div>
          <div style="padding: 0 30px 30px 30px">
            <div class="item">
              <div class="notice_title">跳转链接</div>
              <input
                  v-model="addRobotInfo.linkHref"
                  class="hy-input"
                  placeholder="请输入"
                  type="text"
              />
            </div>
          </div>
        </template>
      </CSDialog>


      <CSDialog
          :dialogShowConfirmBtn="false"
          :dialogVisible="panelGroupDialogVisible"
          dialog-header-class="alert-bg"
          dialogCancelBtnText="关闭"
          dialogTitle="简述"
          dialogWidth="630px"
          @onClose="panelGroupDialogVisible = false"
      >
        <div
            slot="dialog-content"
            style="padding: 30px; font-size: 24px; text-align: center"
        >
          {{ lookGroupPanelCommentInfo }}
        </div>
      </CSDialog>
    </div>
    <ViewImageModal></ViewImageModal>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import ViewImageModal from "@/components/ViewImageModal";
import {
  addWebsiteContentUrl,
  deleteWebsiteContentUrl,
  editWebsiteContentUrl,
  queryWebsiteContentUrl
} from "@/requestUrl";
import Pagination from "@/components/Pagination";

export default {
  name: "Journalism",
  data() {
    return {
      journaList: [], //新闻列表
      lookGroupPanelCommentInfo: "", //简述信息
      panelGroupDialogVisible: false, //查看简述
      search: "",
      filterHeight: 0,
      deleteRobotVisible: false,
      addRobotVisible: false, //是否显示添加弹框
      addRobotDialogTitle: "添加新闻",
      addRobotInfo: {
        robotName: "", //新闻标题
        containerNotice: "", //简述
        linkHref: "", //跳转链接
        iconUrl: "",
        id: "",
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.$vc.on(
        this.$route.path,
        "pagination_page",
        "event",
        pageNo => {
          this.pageParams.pageNo = pageNo;
          this.onRegionConfirm(pageNo)
        }
    );
    window.addEventListener('keydown', this.onRegionConfirmEnd);
  },
  deactivated() {
    window.removeEventListener('keydown', this.onRegionConfirmEnd);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onRegionConfirm);
  },
  mounted() {
    this.onRegionConfirm();
  },
  updated() {
  },
  methods: {
    onRegionConfirmEnd(e) {
      if (e.keyCode == 13) {
        this.onRegionConfirm();
      }
    },
    //查看简述
    lookJianShu(item) {
      this.panelGroupDialogVisible = true;
      this.lookGroupPanelCommentInfo = item;
    },
    //查看配图
    lookImage(src) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", src);
    },
    deletePhotos() {
      this.addRobotInfo.iconUrl = "";
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadStaffPhoto").trigger("click");
    },
    // 选择文件后处理
    async chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2MB!");
          return false;
        }
        let formData = new FormData(); //新建FormData对象
        formData.append("files", photoFiles[0]);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          event.target.value = ""
          this.addRobotInfo.iconUrl = this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
        }
        // var reader = new FileReader(); //新建FileReader对象
        // reader.readAsDataURL(file); //读取为base64
        // reader.onloadend = async (e) => {
        //   const photo = await this.uploadPhoto(reader.result).catch((err) => {
        //     return "";
        //   });
        //   this.addRobotInfo.iconUrl = photo;
        // };
      }
    },
    // 上传图片
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
            "uploadImg",
            "upload",
            {
              img: base64,
            },
            {
              headres: {
                "Content-Type": "application/json",
              },
            },
            (resStr, res) => {
              reslove(res.body.fileSaveName);
            },
            (errText, err) => {
              reject(errText);
            }
        );
      });
    },
    onRegionConfirm(pageNum = this.pageParams.pageNo, pageSiz = 10) {
      this.$fly
          .post(queryWebsiteContentUrl, {
            title: this.search, // 标题  非必填
            regionCode: this.$vc.getCurrentRegion().code, // 园区code 必填
            type: 1, // 类型，必填
            pageSize: pageSiz, // 必填
            pageNum: pageNum, // 必填
          })
          .then((res) => {
            this.journaList = res.data.datas;
            if (pageNum === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total,
                pageSize: pageSiz,
                currentPage: pageNum,
              });
            }
          });
    },
    //查看公告
    lookVideo() {
    },
    //修改
    editRobot() {
    },
    //删除
    //确认删除
    deleteRobot() {
      this.$fly.delete(`${deleteWebsiteContentUrl}/${this.addRobotInfo.id}`).then(res => {
        if (res.code != 0) {
          return
        }
        this.$vc.toast("删除成功");
        this.deleteRobotVisible = false;
        this.onRegionConfirm();
      })
    },
    //添加公告弹出层
    showAddRobotDialog(index, item) {
      if (index == 1) {
        this.addRobotDialogTitle = "添加新闻";
        this.addRobotInfo.robotName = "";
        this.addRobotInfo.containerNotice = "";
        this.addRobotInfo.iconUrl = "";
        this.addRobotInfo.linkHref = "";
        this.addRobotInfo.id = "";
      } else {
        this.addRobotInfo.robotName = item.title;
        this.addRobotInfo.containerNotice = item.content;
        this.addRobotInfo.iconUrl = item.picUrl;
        this.addRobotInfo.linkHref = item.contentUrl;
        this.addRobotInfo.id = item.id;
        this.addRobotDialogTitle = "修改新闻";
      }
      this.addRobotVisible = true;
    },
    //添加新闻
    addRobotDevice() {
      if (!this.addRobotInfo.iconUrl || !this.addRobotInfo.linkHref) {
        this.$vc.toast('请填写完整信息');
        return
      }
      this.$fly
          .post(addWebsiteContentUrl, {
            regionCode: this.$vc.getCurrentRegion().code, // 园区code 必填
            title: this.addRobotInfo.robotName, //标题 必填
            content: this.addRobotInfo.containerNotice, // 公告为内容  新闻为简述
            type: 1,
            picUrl: this.addRobotInfo.iconUrl, //新闻配图
            contentUrl: this.addRobotInfo.linkHref, //新闻跳转链接
          })
          .then((res) => {
            this.addRobotInfo.robotName = "";
            this.addRobotInfo.containerNotice = "";
            this.addRobotInfo.iconUrl = "";
            this.addRobotInfo.linkHref = "";
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("添加成功");
            this.onRegionConfirm();
            this.addRobotVisible = false;
          });
    },
    //修改新闻
    editRobotDevice(item) {
      this.$fly
          .post(editWebsiteContentUrl, {
            id: this.addRobotInfo.id,
            regionCode: this.$vc.getCurrentRegion().code, // 园区code 必填
            title: this.addRobotInfo.robotName, //标题 必填
            content: this.addRobotInfo.containerNotice, // 公告为内容  新闻为简述
            type: 1,
            picUrl: this.addRobotInfo.iconUrl, //新闻配图
            contentUrl: this.addRobotInfo.linkHref, //新闻跳转链接
          })
          .then((res) => {
            this.addRobotInfo.robotName = "";
            this.addRobotInfo.containerNotice = "";
            this.addRobotInfo.iconUrl = "";
            this.addRobotInfo.linkHref = "";
            this.addRobotInfo.id = ""
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("修改成功");
            this.onRegionConfirm();
            this.addRobotVisible = false;
          });
    },
  },
  components: {
    CSTable,
    CSDialog,
    ViewImageModal,
    Pagination,
  },
};
</script>

<style scoped>
.sticky-right {
  float: right;
  height: 35px;
}

.notice_title {
  display: inline-block;
  width: 146px;
  font-size: 24px;
  color: #000;
  text-align: right;
  margin-right: 40px;
  vertical-align: middle;
}
</style>
